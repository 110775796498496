import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="links Top-Links">
        <a href="https://discord.com/invite/ZGG393JEa4" className="top-links">
          <img src="/assets/discord.svg" alt=""  />
        </a>
        <a href="https://twitter.com/inspiring_nft_" className="image-last">
          <img src="./assets/twitter.svg" alt="" className="last" />
        </a>
      </div>
      <img src="./Website/Banner.png" alt="" className="image1" />
      <img src="./Website/Website 2.png" alt="" className="image2" />
      <img src="./Website/Roadmap.png" alt="" className="image3"
      />
      <img src="./Website/Frequently asked questions.png" alt="" />
      <img src="./Website/Team.png" alt="" />
      <div className="links">
        <a href="https://discord.com/invite/ZGG393JEa4"  className="top-links">
          <img src="./assets/discord.svg" alt=""  />
        </a>
        <a href="https://twitter.com/inspiring_nft_" className="image-last">
          <img src="./assets/twitter.svg" alt="" className="last" />
        </a>
      </div>
    </div>
  );
}

export default App;
